<template>
  <div class="mt-7">
    <v-row>
      <v-col cols="4" class="">
      <fieldsRows
          :templateContent="templateContentRow"
          :valueLine="valueLine"
          :isValid="isValid"
          v-on="$listeners"
      ></fieldsRows>
      </v-col>
        <v-col class="mt-n3" style="max-width: fit-content;">
          <v-switch
              v-model="localValue"
              label="Advanced Options"
              :rules="[]"
              v-bind="{ id:'advanceOptions' }"
          ></v-switch>
            </v-col>
    </v-row>
    <v-row v-if="advanceOption" class="mt-n3">
      <v-col cols="8">
        <multipleSelect
            :fieldAttributes="{ options: result.codecs, name: 'Activated Codecs',ignoreEmptyAdd:true,style:'max-width:fit-content;' }"
            :fieldAttrInput="{ id:'activeCodecs' }"
            v-model="activeCodecs"
            :templateContent="result"
            :isValid="isValid"
            :checkBox="true"
        >
        </multipleSelect>
      </v-col>
    </v-row>
  </div>
</template>
<script>

import { getfieldAttrInput } from "@/js/helper.js";
import fieldsRows from "@/commonComponents/fieldsRows.vue";
import multipleSelect from '@/commonComponents/multipleSelect.vue';
import options from "@/cfg/options.json";


export default {
  components: {
    fieldsRows,
    multipleSelect
  },
  props: ["field", "fieldAttributes", "result", "valueLine", "isValid"],

  data() {
    return {
      localCodes: ["ipv4", "ipv6"],
      options: options,
      advanceOption: false
    };
  },
  computed: {
    localValue: {
      get() {
        return  this.advanceOption;
      },
      set(newValue) {
       this.advanceOption = newValue;
      },
    },
    templateContentRow() {
      let templateContentRow = {
        fields: {
          congestionHighTimeLine: {
            type: "line",
            fields: {
              duration: {
                type: "number",
                fieldAttrInput: { class: "" },
                colAttrs: { style: 'max-width:250px', class: 'mr-12' },
                name: "Call Duration in Seconds",
              },
              checkAudioQuality: {
                type: "switch",
                fieldAttrInput: { class: "" },
                colAttrs: { class: "mt-n3 ml-7" },
                name: "Check Audio Quality",
              },
            },
          },
        },
        disableAllInputs: this.result.disableAllInputs,
        contentID: this.result.contentID,
      };
      return templateContentRow;
    },
    fieldAttrInput() {
      return getfieldAttrInput(this.fieldAttributes);
    },
    activeCodecs: {
      get() {
        let activeCodecsVal = []
        if (typeof this.valueLine != "undefined" &&
            typeof this.valueLine.activeCodecs != "undefined"
        ) {
          activeCodecsVal = this.valueLine.activeCodecs;
        }
        return activeCodecsVal;
      },
      set(newVal) {
        this.$set(this.valueLine, "activeCodecs", newVal);
      },
    },
  },
  methods: {
    updateValue(value) {
      console.log('---->'.value);
      this.$emit("input", {
        advanceOption: value,
      });
    },
  },
};
</script>